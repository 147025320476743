import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileAbout} w="100%" alt="Golden Spring About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView m="0 auto">
          <CFView row justifyStart alignCenter w="100%" maxWidth="2000px">
            <CFImage src={about} w="100%" alt="Promotions" />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
